<template>
    <b-container>
        <b-modal id="modal-session-app" ok-only ok-variant="warning" ok-title="Login" modal-class="modal-session-app"
            centered title="Expired !" @ok="gotologin()">
            <b-card-text>Session Expired</b-card-text>
        </b-modal>
        <b-form @submit.prevent="getSuggestions()">
            <b-card class="d-flex mb-0">
                <div class="d-flex flex-wrap align-items-center justify-content-between border rounded p-1">
                    <div class="d-flex align-items-center justify-content-between flex-auto">
                        <div class="d-flex align-items-center justify-content-between">
                            <svg class="kOqhQd" style="width: 53px" aria-hidden="true" viewBox="0 0 40 40"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill="none" d="M0,0h40v40H0V0z"></path>
                                <g>
                                    <path
                                        d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z"
                                        fill="#EA4335"></path>
                                    <path
                                        d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z"
                                        fill="#FBBC04"></path>
                                    <path d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z"
                                        fill="#4285F4"></path>
                                    <path
                                        d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z"
                                        fill="#34A853"></path>
                                </g>
                            </svg>
                            <h3 class="function-title d-none d-md-block">Keyword Suggests</h3>
                        </div>

                        <div class="d-flex flex-wrap align-items-center justify-content-around flex-auto"
                            style="gap:7px">
                            <b-form-group class="mb-0" style="width: 50%">
                                <b-form-input type="text" class="form-control" placeholder="Type a keyword..." autofocus
                                    id="search-input" v-model="search" />
                                <small ref="searchError" class="text-danger"></small>
                            </b-form-group>
                            <b-form-group class="mb-0" style="width: 45%">
                                <b-form-select :options="languages" v-model="language"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success"
                        class="circle btn-search" type="submit" v-b-tooltip.hover title="Search">
                        <feather-icon icon="SearchIcon" size="14" />
                    </b-button>

                </div>
            </b-card>
        </b-form>
        <div>
            <b-card ref="suggestionsCard" class="hidden mt-2">
                <b-row v-show="progress">
                    <b-col cols="12">
                        <div class="text-center my-4 mb-5">
                            <b-spinner class="spinner" variant="primary" size="60" label="Text Centered" />
                        </div>
                    </b-col>
                </b-row>

                <b-row v-show="!progress" class="justify-content-center">
                    <b-col class="col-md-4 col-12">
                        <div class="suggestions-container border rounded mb-2 pb-1">
                            <h4 class="text-center mt-2">
                                {{ searchKeyword }}
                            </h4>
                            <hr />
                            <div class="d-flex justify-content-between align-items-center px-1 pb-1" v-bind:key="index"
                                v-for="(suggest, index) of suggestions_primary">
                                <span> {{ suggest.term }}</span>
                                <a class="btn btn-sm btn-info" @click="redirectToSearch(suggest.term)">
                                    <feather-icon icon="SearchIcon" size="10" />
                                </a>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="col-md-4 col-12" v-bind:key="index"
                        v-for="(suggests, index) of suggestions_secondary">
                        <div class="suggestions-container border rounded mb-2 pb-1">
                            <h4 class="text-center mt-2">
                                {{ searchKeyword + " " + suggests.prefix.toUpperCase() }}
                            </h4>
                            <hr />
                            <div class="d-flex justify-content-between align-items-center px-1 pb-1" v-bind:key="i"
                                v-for="(suggest, i) of suggests.suggestions">
                                <span> {{ suggest.term }}</span>
                                <a class="btn btn-sm btn-info" @click="redirectToSearch(suggest.term)">
                                    <feather-icon icon="SearchIcon" size="10" />
                                </a>
                            </div>
                        </div>
                    </b-col>
                    <hr class="col-12" />
                </b-row>

                <b-row v-if="!isDatabase && !progress" class="justify-content-center">
                    <button class="btn btn-primary btn-lg" @click="saveSuggestions()">
                        Save
                    </button>
                </b-row>
            </b-card>
        </div>
        <router-view></router-view>
    </b-container>
</template>

<script>
import {
    BRow,
    BCol,
    BModal,
    BCard,
    BFormSelect,
    BTable,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BFormFile,
    BImg,
    BForm,
    BInputGroupAppend,
    BCardText,
    BButtonGroup,
    VBTooltip,
    BPagination,
    BButton,
    VBHover,
    BContainer,
    BFormInvalidFeedback,
    BAspect,
    BSpinner,
} from "bootstrap-vue";
import axios from "@axios";
import appsAxios from "@/libs/appsAxios";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import Ripple from "vue-ripple-directive";
import Editor from "@tinymce/tinymce-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        BRow,
        BCol,
        BFormTextarea,
        BCard,
        BContainer,
        BFormInput,
        BFormGroup,
        BFormFile,
        BImg,
        BFormSelect,
        BInputGroupAppend,
        BForm,
        BTable,
        BCardText,
        BInputGroup,
        BModal,
        BButtonGroup,
        VBTooltip,
        BPagination,
        BButton,
        BAspect,
        editor: Editor,
        BFormInvalidFeedback,
        BSpinner,
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-hover": VBHover,
        Ripple,
    },
    data() {
        return {
            search: null,
            searchKeyword: null,
            suggestions_primary: [],
            suggestions_secondary: [],
            characters: [
                "a",
                "b",
                "c",
                "d",
                "e",
                "f",
                "g",
                "h",
                "i",
                "j",
                "k",
                "l",
                "m",
                "n",
                "o",
                "p",
                "q",
                "r",
                "s",
                "t",
                "u",
                "v",
                "w",
                "x",
                "y",
                "z",
                // "0",
                // "1",
                // "2",
                // "3",
                // "4",
                // "5",
                // "6",
                // "7",
                // "8",
                // "9",
            ],
            charactersAr: [
                "أ",
                "ب",
                "ت",
                "ث",
                "ج",
                "ح",
                "خ",
                "د",
                "ذ",
                "ر",
                "ز",
                "س",
                "ش",
                "ص",
                "ض",
                "ط",
                "ظ",
                "ع",
                "غ",
                "ف",
                "ق",
                "ك",
                "ل",
                "م",
                "ن",
                "ه",
                "و",
                "ي",
            ],
            countries: [],
            country: "ma",
            isDatabase: true,
            progress: true,
            languages: [
                { value: "En", text: "English" },
                { value: "Ar", text: "Arabe" },
            ],
            language: "En",
            token: null,
            server: 1,
        };
    },
    mounted() {
        this.token = JSON.parse(localStorage.getItem("userAccess")).token;
        this.server = JSON.parse(localStorage.getItem("userAccess")).server;
    },
    created() { },
    watch: {},
    methods: {
        redirectToSearch(query) {
            this.$router.push({
                name: "market-manager-applications-search",
                query: { search: query },
            });
        },
        getCountries() {
            axios
                .get("/countries")
                .then((response) => response.data)
                .then((data) => {
                    data.countries.forEach((country) => {
                        this.countries.push({
                            value: country.code,
                            text: country.name,
                        });
                    });
                })
                .catch(console.error);
        },
        saveSuggestions() {
            axios
                .post("/store-suggestions", {
                    suggestion: this.search,
                    language: this.language,
                    suggestions_primary: this.suggestions_primary,
                    suggestions_secondary: this.suggestions_secondary,
                })
                .then((response) => response.data)
                .then((data) => {
                    if (data.status == "Unauthorized") {
                        removeLocalStorageItems();
                        this.$router.push({ name: "auth-login" });
                    }

                    this.showToast("success", "SaveIcon", "Done", data.message);
                })
                .catch((exception) => {
                    this.showToast(
                        "danger",
                        "AlertTriangleIcon",
                        "Error",
                        "Something went Wrong!"
                    );
                });
        },
        getSuggestions() {
            if (this.search) {
                this.progress = true;
                this.searchKeyword = this.search;
                this.$refs.searchError.textContent = "";
                this.$refs.suggestionsCard.classList.remove("hidden");
                this.suggestions_primary = [];

                axios
                    .get(
                        `/get-suggestions?suggestion=${this.search}&language=${this.language}`
                    )
                    .then((response) => response.data)
                    .then((data) => {
                        if (data.status == "Unauthorized") {
                            removeLocalStorageItems();
                            this.$router.push({ name: "auth-login" });
                        }

                        if (data.status === "success") {
                            this.isDatabase = true;
                            this.suggestions_primary = [];
                            data.primarySuggestions.forEach((sug, index) => {
                                this.suggestions_primary.push({
                                    ...sug,
                                    position: index + 1,
                                });
                            });

                            this.suggestions_secondary = [];
                            this.characters.forEach((char) => {
                                const test = data.secondarySuggestions.filter(
                                    (suggest) => suggest.prefix === char.toUpperCase()
                                );
                                if (test.length > 0) {
                                    this.suggestions_secondary.push({
                                        suggestions: test,
                                        prefix: char,
                                    });
                                }
                            });

                            this.charactersAr.forEach((char) => {
                                const test = data.secondarySuggestions.filter(
                                    (suggest) => suggest.prefix === char.toUpperCase()
                                );
                                if (test.length > 0) {
                                    this.suggestions_secondary.push({
                                        suggestions: test,
                                        prefix: char,
                                    });
                                }
                            });
                        } else if (data.status === "failed") {
                            this.isDatabase = false;
                            appsAxios.get( `https://sc1.clicklab.app/api/apps/?suggest=${this.search.trim()}`)
                            .then((response) => {
                                const { status } = response.data;
                                if (status === "Unauthorized") {
                                    removeLocalStorageItems();
                                    this.$root.$emit("bv::show::modal", "modal-session-app");
                                    return;
                                }
                                const { results } = response.data;
                                return results;
                            })
                            .then((results) => {
                                if (results.length > 0) {
                                    results.forEach((result, index) => {
                                        this.suggestions_primary.push({
                                            ...result,
                                            position: index + 1,
                                        });
                                    });
                                }
                            })
                            .catch(console.error);

                            this.suggestions_secondary = [];
                            if (this.language == "Ar") {
                                this.charactersAr.forEach((char) => {
                                    appsAxios.get(`https://sc1.clicklab.app/api/apps/?suggest=${this.search.trim()} ${char}`)
                                    .then((response) => {
                                        const { status } = response.data;
                                        if (status === "Unauthorized") {
                                            removeLocalStorageItems();
                                            this.$root.$emit(
                                                "bv::show::modal",
                                                "modal-session-app"
                                            );
                                            return;
                                        }
                                        const { results } = response.data;
                                        return results;
                                    })
                                    .then((results) => {
                                        if (results.length > 0) {
                                            // results.forEach((result, index) => {
                                            //   this.suggestions_secondary.push({
                                            //     suggestion: result,
                                            //     prefix: char,
                                            //     position: index + 1,
                                            //   });
                                            // });

                                            this.suggestions_secondary.push({
                                                suggestions: results,
                                                prefix: char,
                                            });
                                        }
                                    })
                                    .catch(console.error);
                                });
                            } else {
                                this.characters.forEach((char) => {
                                    appsAxios.get(`https://sc1.clicklab.app/api/apps/?suggest=${this.search.trim()} ${char}`)
                                    .then((response) => {
                                        const { status } = response.data;
                                        if (status === "Unauthorized") {
                                            removeLocalStorageItems();
                                            this.$root.$emit(
                                                "bv::show::modal",
                                                "modal-session-app"
                                            );
                                            return;
                                        }
                                        const { results } = response.data;
                                        return results;
                                    })
                                    .then((results) => {
                                        if (results.length > 0) {
                                            // results.forEach((result, index) => {
                                            //   this.suggestions_secondary.push({
                                            //     suggestion: result,
                                            //     prefix: char,
                                            //     position: index + 1,
                                            //   });
                                            // });

                                            this.suggestions_secondary.push({
                                                suggestions: results,
                                                prefix: char,
                                            });
                                        }
                                    })
                                    .catch(console.error);
                                });
                            }
                        }
                    })
                    .catch(console.error);

                setTimeout(() => {
                    this.progress = false;
                }, 1000);
            } else {
                this.$refs.searchError.textContent = "The Search Field Is Required!";
            }
        },
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    text,
                    variant,
                },
            });
        },
        gotologin() {
            this.$router.push({ name: "auth-login" });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";

.flex-auto {
    flex: auto;
    margin-right: 5px;
}

@media only screen and (max-width: 740px) {

    .btn-search,
    .input-search {
        width: 100% !important;
    }

}
</style>
